.container {
    width: 91%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 100px;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.bookingSection {
    display: flex;
    flex-direction: row;
    column-gap: 50px;
}

.leftSection {
    width: 33.33%;
    display: flex;
    flex-direction: column;
    /* row-gap: 30px; */
}

.docImage {
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 20px;
    object-fit: cover;
}

.docName {
    color: #212022;
    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 88%;
    margin-top: 30px;
}

.rightSection {
    flex: 1 1 0;
    display: flex;
    flex-direction: column;
}

.docDetails {
    display: flex;
    flex-direction: column;
}

.docDept {
    color: #5D5D5D;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    margin-top: 16px;
}

.qualificationDiv {
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
    margin-top: 16px;
}

.docQualification {
    display: flex;
    padding: 5px 10px;
    align-items: center;
    gap: 9px;
    border-radius: 10px;
    background: #DDCAEB;

    color: #542F68;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
}

.appointmentTag {
    width: fit-content;
    color: var(--primary-300);
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    border-radius: 100px;
    background: var(--bg-primary-200);
    display: flex;
    padding: 2px 20px;
    align-items: center;
    gap: 9px;

    margin-bottom: 20px;
}

@media(max-width: 1200px) {
    .container {
        width: 92%;
        margin-top: 30px;
    }


}

@media(max-width: 1024px) {
    .bookingSection {
        display: flex;
        flex-direction: column;
        row-gap: 50px;
    }

    .leftSection {
        width: 100%;
        display: flex;
        flex-direction: row;
        column-gap: 20px;
    }

    .docImage {
        width: 22%;
        aspect-ratio: 1/1;
        border-radius: 20px;
        margin-right: 20px;
    }

    .docName {
        font-size: 24px;
        line-height: 88%;
        margin-top: 0;
    }
}

@media(max-width: 768px) {
    .container {
        margin-bottom: 50px;
        width: 88%;
        gap: 30px;
    }

    .docName {
        font-size: 16px;
        line-height: 88%;
    }


    .docQualificationDiv {
        margin-top: 10px;
    }

    .docQualification {
        font-size: 14px;
    }

    .docDept {
        font-size: 12px;
        line-height: normal;
        margin-top: 10px;
    }

    .appointmentTag {
        font-size: 12px;
        margin-bottom: 10px;
    }
}

@media(max-width: 600px) {

    .leftSection {
        width: 100%;
        display: flex;
        flex-direction: column;
        /* column-gap: 20px; */
    }

    .docName {
        margin-top: 20px;
    }


    .docImage {
        width: 100%;
        aspect-ratio: 1/1;
        border-radius: 20px;
        margin-right: 0;
    }
}