.outerContainer {
    padding: 30px 50px 50px 50px;
}

.loadingDiv {
    display: flex;
    justify-content: center;
}

.loadingIcon {
    font-size: 36px;
    color: var(--primary-300);
}

/* :global(.loadingIcon svg path) {
    stroke-width: 10;
} */

.menuContainer {
    width: 100%;
    display: flex;
    gap: 30px;
}

.leftSection {
    width: 54.5%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.leftContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
}

.leftContainer a {
    text-decoration: none;
}

.leftTitle {
    color: var(--primary-300);
    font-family: Montserrat;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.divideLine {
    width: 68.4%;
    height: 1px;
    background: var(--primary-700);
    margin-bottom: 10px;
}

.card {
    display: flex;
    min-height: 120px;
    padding: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    border-radius: 20px;

    cursor: pointer;
}

.card:hover {
    background: var(--bg-primary-200);
}

.locationDiv {
    display: flex;
    gap: 5px;
}

.branchName {
    color: var(--primary-300);
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;


    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.contactDiv {
    display: flex;
    gap: 9px;
}

.text {
    color: #542F68;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}



.rightSection {
    width: 43.2%;
    flex: 1 0 0;
}

.sliderContainer {
    width: 100%;
    height: 386px;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
}


.sliderImage {
    width: 100%;
    object-fit: cover;
    height: 100%;
    border-radius: 20px;
    overflow: hidden;
}

.swiperImageText {
    position: absolute;
    left: 25px;
    bottom: 50px;
    /* bottom: 76px; */

    color: #FFF;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.buttonsDiv {
    display: flex;
    gap: 20px;
    position: absolute;
    bottom: 25px;
    right: 25px;
    z-index: 10;
}

.leftIcon {
    cursor: pointer;
    /* color: #FFF; */
}

.rightIcon {
    cursor: pointer;
    /* color: #FFF; */
}