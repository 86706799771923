.sectionContianer {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.sectionHeading {
  color: #212022;
  font-family: Montserrat;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 46.8px;
  margin-bottom: 20px;
}

.sectionHeading span {
  color: var(--primary-500);
}

.divideLine {
  width: 300px;
  height: 1px;
  background: var(--bg-primary-500);
  margin-bottom: 50px;
}

.notAvailable {
  color: var(--primary-500);
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 46.8px;
  margin-top: 20px;
  text-align: center;
}

.loadingIcon {
  /* margin-top: 50px; */
  margin-left: auto;
  margin-right: auto;
  font-size: 36px;
}

.slotsContainer {
  /* display: flex;
    flex-direction: row;
    flex-wrap: wrap; */
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;

  margin-bottom: 36px;
  width: 100%;
}

.slot {
  display: flex;
  align-items: center;
  gap: 20px;
  border-radius: 10px;
  border: 1px solid #ccc;
  background: #f4eff9;
  padding: 10px 20px;
}

.checkBox {
  cursor: pointer;
}

.slots h4 {
  color: #635f66;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 133%;
}

.timeList {
  display: flex;
  gap: 8px;
}

.timeList img {
  width: 15px;
}

.timeList p {
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 133%;
}

.submitButton {
  cursor: pointer;
  display: flex;
  /* height: 44px; */
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: #fff;
  border-radius: 100px;
  padding: 15px 20px;

  border: 4px solid var(--primary-500);

  color: var(--primary-700);
  font-size: 16px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;

  /* margin-left: auto;
    margin-right: auto; */
  margin-top: 16px;
}

.message {
  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 36px;
}

.noDocMessage {
  color: #000;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 50%;
  /* text-align: center */
}

.contactUs {
  width: fit-content;
  border-radius: 50px;
  padding: 0 20px;
  height: 50px;
  color: var(--primary-700);
  font-size: 16px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
  border: 4px solid var(--primary-500);
  margin-top: 36px;
}

@media (max-width: 1200px) {
  .sectionHeading {
    color: #212022;
    font-size: 32px;
    line-height: normal;
  }

  .notAvailable {
    font-size: 18px;
    line-height: normal;
  }

  .divideLine {
    margin-bottom: 30px;
  }
}

@media (max-width: 768px) {
  .sectionHeading {
    color: #212022;
    font-size: 20px;
    line-height: normal;
  }

  .notAvailable {
    font-size: 16px;
    line-height: normal;
    margin-bottom: 10px;
  }

  .divideLine {
    margin-bottom: 20px;
  }

  .loadingIcon {
    /* margin-top: 30px; */
    font-size: 30px;
  }

  .slotsContainer {
    grid-template-columns: repeat(1, 1fr);
    /* flex-direction: column;
        flex-wrap: wrap; */
    gap: 10px;

    margin-bottom: 20px;
  }

  .slot {
    gap: 10px;
  }

  .slots h4 {
    font-size: 14px;
    line-height: normal;
  }

  .timeList p {
    font-size: 14px;
  }

  .submitButton {
    font-size: 12px;
    padding: 5px 10px;
  }

  .message {
    font-size: 12px;
    margin-top: 20px;
  }

  .noDocMessage {
    font-size: 14px;
    margin-top: 0;
    width: 300px;
  }

  .contactUs {
    height: 40px;
    font-size: 12px;
    padding: 0 10px;
    margin-top: 20px;
  }
}

@media (max-width: 480px) {
  .noDocMessage {
    width: 290px;
  }
}

@media (max-width: 350px) {
  .noDocMessage {
    width: 100%;
  }
}
