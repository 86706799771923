.appContainer {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.appContainer main {
    flex: 1;
}

.navbarLayout {
    position: sticky;
    top: 0;
    z-index: 100;
}