.sectionContianer {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.sectionHeading {
    color: #212022;
    font-family: Montserrat;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 46.8px;
    margin-bottom: 20px;
}

.sectionHeading span {
    color: var(--primary-500);
}

.textLine {
    color: #5D5D5D;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    margin-top: 20px;
}

.otpInputSection {
    margin: 50px 0;
}

.submitButton {
    width: fit-content;
    cursor: pointer;
    display: flex;
    /* height: 44px; */
    align-items: center;
    justify-content: center;
    gap: 10px;
    background-color: #fff;
    border-radius: 100px;
    padding: 15px 20px;

    border: 4px solid var(--primary-500);

    color: var(--primary-700);
    font-size: 16px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;

    /* margin-left: auto;
    margin-right: auto; */
}

.resendOtp {
    color: #000;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 16px;
}

.resendOtp .resendTime {
    color: var(--primary-500);
    font-weight: 500;
    text-decoration: none !important;
    cursor: auto;
}

.resendOtp span {
    color: #006FD7;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: auto;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;
    cursor: pointer;
    font-weight: 500;
}

@media(max-width: 1200px) {
    .sectionHeading {
        color: #212022;
        font-size: 32px;
        line-height: normal;
    }

    .otpInputSection {
        margin: 40px 0;
    }
}

@media(max-width: 768px) {
    .sectionHeading {
        color: #212022;
        font-size: 20px;
        line-height: normal;
    }

    .textLine {
        font-size: 12px;
        line-height: normal;
        margin-top: 10px;
    }

    .otpInputSection {
        margin: 30px 0;
    }

    .submitButton {
        font-size: 12px;
        padding: 5px 10px;
    }

    .resendOtp {
        font-size: 12px;
        margin-top: 5px;
    }
}