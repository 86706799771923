.sectionContianer {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.sectionHeading {
    color: #212022;
    font-family: Montserrat;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 46.8px;
    margin-bottom: 20px;
}

.sectionHeading span {
    color: var(--primary-500);
}

.textLine {
    color: #5D5D5D;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    /* margin-top: 20px; */
}

.patientSection {
    margin: 50px 0;
}

.loadingIcon {
    /* margin-top: 50px; */
    margin-left: auto;
    margin-right: auto;
    font-size: 36px;
}

.patientsContainer {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.slot {
    display: flex;
    align-items: center;
    gap: 20px;
    border-radius: 10px;
    border: 1px solid #ccc;
    background: #f4eff9;
    padding: 10px 20px;
}

.checkBox {
    cursor: pointer;
}

.slots {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.slots h4 {
    color: #212022;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
}

.marginRight {
    margin-left: 20px;
}

.spanWeight {
    font-weight: 600;
}

.formInputContainer {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.inputHalf {
    width: 50%;
    margin-bottom: 20px;
}

.inputFull {
    width: 100%;
    margin-bottom: 20px;
}

.buttonDiv {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.submitButton {
    flex: 1 1 0;
    cursor: pointer;
    display: flex;
    /* height: 44px; */
    align-items: center;
    justify-content: center;
    gap: 10px;
    background-color: #fff;
    border-radius: 100px;
    padding: 15px 20px;

    border: 4px solid var(--primary-500);

    color: var(--primary-700);
    font-size: 16px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;

    /* margin-left: auto;
    margin-right: auto; */
    margin-top: 16px;
}

.submitButton2 {
    flex: 1 1 0;
    cursor: pointer;
    display: flex;
    /* height: 44px; */
    align-items: center;
    justify-content: center;
    gap: 10px;
    background-color: #fff;
    border-radius: 100px;
    padding: 15px 20px;

    border: 1px solid var(--primary-700);

    color: var(--primary-700);
    font-size: 16px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;

    /* margin-left: auto;
    margin-right: auto; */
    margin-top: 16px;
}

.notAvailable {
    color: var(--primary-500);
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 46.8px;
    margin-top: 20px;
    text-align: center;
}

.marginAuto {
    margin-left: auto;
    margin-right: auto;
}

@media(max-width: 1200px) {
    .sectionHeading {
        color: #212022;
        font-size: 32px;
        line-height: normal;
    }

    .patientSection {
        margin: 40px 0;
    }

    .inputHalf {
        width: 100%;
    }

    .notAvailable {
        font-size: 18px;
        line-height: normal;
    }

}

@media(max-width: 768px) {
    .sectionHeading {
        color: #212022;
        font-size: 20px;
        line-height: normal;
    }

    .textLine {
        font-size: 12px;
        line-height: normal;
        /* margin-top: 10px; */
    }

    .patientSection {
        margin: 30px 0;
    }

    .loadingIcon {
        /* margin-top: 30px; */
        font-size: 30px;
    }

    .patientsContainer {
        gap: 10px;
    }

    .slot {
        gap: 10px;
    }

    .slots {
        gap: 10px;
    }

    .slots h4 {
        font-size: 14px;
        line-height: normal;
    }

    .marginRight {
        margin-left: 5px;
    }

    .formInputContainer {
        flex-direction: column;
        gap: 0px;
    }

    .buttonDiv {
        gap: 10px;
    }


    .submitButton {
        font-size: 12px;
        padding: 5px 10px;
    }

    .submitButton2 {
        font-size: 12px;
        padding: 5px 10px;
    }

    .notAvailable {
        font-size: 16px;
        line-height: normal;
        margin-bottom: 10px;
    }
}