.navbarDiv {
    background-color: #fff;
    backdrop-filter: blur(100px);
    font-family: 'Montserrat', sans-serif !important;
    box-shadow: 0px 308px 86px 0px rgba(0, 0, 0, 0.00), 0px 197px 79px 0px rgba(0, 0, 0, 0.01), 0px 111px 66px 0px rgba(0, 0, 0, 0.03), 0px 49px 49px 0px rgba(0, 0, 0, 0.04), 0px 12px 27px 0px rgba(0, 0, 0, 0.05);
}

.container {
    height: 64px;
    padding: 0px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo {
    max-width: 170px;
}

.linksDiv {
    display: flex;
    gap: 5px;
    margin: 0;
    padding: 0;
}

.linksDiv a {
    text-decoration: none !important;
}

.link {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 5px;
    text-decoration: none !important;
}

.hospitalLink{
    position: relative;
    z-index: 5;
}

/* .dropLink{
    z-index: 5;
} */

.transparentDiv{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: transparent;
    height: 78px;
}

.link div {
    color: var(--primary-500);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.dropLink {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: var(--primary-500);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.rightSection {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.phoneDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.phoneDiv h4 {
    color: var(--primary-500);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
}

.bookOnline {
    cursor: pointer;
    display: flex;
    /* height: 44px; */
    padding: 5px;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: #fff;
    /* background-color: var(--bg-p); */
    border-radius: 100px;
    padding: 5px 10px;
    border: 2px solid var(--primary-500);
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    transition: all 0.2s;
}

.bookOnline:hover{

    background-color: #fff;
    color: var(--primary-700)

}

.humburger {
    cursor: pointer;
    margin-left: 10px;
    width: 25px;
    height: 18px;
}

.drawer {
    width: 250px;
}

.closeIconDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26px;
    height: 26px;
    cursor: pointer;
    position: absolute;
    top: 1px;
    left: 1px;
}

.closeIcon {
    width: 10px;
    height: 10px;
}

.menuListDiv {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.menuLogo {
    max-width: 75%;
}

.quickContact {
    display: flex;
    flex-direction: column;
}

.quickContactHead {
    color: var(--primary-500);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    text-transform: capitalize;
    margin: 0 0 15px 0;
}

.quickContactItemDiv {
    display: flex;
    align-items: center;
    gap: 7px;
    margin: 0 0 10px 0;
}

.phoneIcon {
    width: 13px;
}

.whatsappIcon {
    width: 15px;
}

.quickContactItem {
    color: var(--primary-500);
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    text-transform: capitalize;
    margin: 0;
    text-decoration: none;
}

.quickContactItem:hover {
    color: var(--primary-500);
}

.menuItems {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.menuItem {
    display: flex;
    padding: 10px;
    gap: 10px;
    border-radius: 5px;
}

.bgWhite {
    background-color: #FFF;
}

.bgPrimary {
    background-color: var(--primary-500);
}

.menuText {
    color: var(--primary-500);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.textWhite {
    color: #FFF;
}

.textPrimary {
    color: var(--primary-500);
}

@media (min-width:768px) {
    .container {
        height: 74px;
        padding: 0px 30px;
    }
    

    .logo {
        max-width: 212px;
    }

    .bookOnline {
        font-size: 16px;
        padding: 8px 20px;
    }

    .humburger {
        margin-left: 5px;
        width: 34px;
        height: 24px;
    }

    .drawer {
        width: 400px;
    }

    .menuListDiv {
        gap: 30px;
    }

    .menuLogo {
        width: 100%;
        max-width: none;
    }

    .quickContactHead {
        font-size: 20px;
    }

    .quickContactItem {
        font-size: 16px;
    }

    .phoneIcon {
        width: 15px;
    }

    .whatsappIcon {
        width: 18px;
    }

    .menuText {
        font-size: 16px;
    }

    .menuItems {
        gap: 10px;
    }
}

@media (min-width:1200px) {
    .closeIconDiv {
        width: 44px;
        height: 44px;
        top: 8px;
        left: 8px;
    }

    .closeIcon {
        width: 18px;
        height: 18px;
    }

    .menuListDiv {
        gap: 32px;
    }
}

@media (min-width:1400px) {
    .container {
        height: 78px !important;
        padding: 0px 50px;
    }

    .logo {
        max-width: 290px;
    }

    .drawer {
        width: 434px;
    }

}

@media (min-width:1800px) {}