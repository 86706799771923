body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: "Montserrat", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  margin-top: 0;
  margin-bottom: 0;
}

p {
  margin: 0;
}

h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* .branchSwiper .swiper-slide-active .branch-image {
  display: block !important;
} */

.branchSwiper .swiper-slide .branch-outer {
  background: var(--bg-primary-300) !important;
  /* align-items: flex-start !important; */
  /* opacity: 1 !important; */
  /* animation: fadeIn 0.5s ease-in forwards !important; */
}

/* .branchSwiper .swiper-slide-active .branch-outer button {
  border-radius: 100px !important;
  border: 1px solid #d2d0d3 !important;
  background: #fff !important;
  color: #542f68 !important;
  margin-top: 0 !important;
} */

/* .branchSwiper .swiper-slide-active .branch-outer h2 {

  text-align: left !important;
} */

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.branchSwiper .swiper-slide {
  margin-top: auto !important;
  margin-bottom: auto !important;
  text-align: left;
}

:root {
  --primary-900: #212022;
  --primary-700: #2d1739;
  --primary-500: #7f4a9b;
  --primary-300: #542f68;
  --primary-100: #f1f0f1;
  --bg-primary-700: #2d1739;
  --bg-primary-500: #7f4a9b;
  --bg-primary-400: #f1f0f1;
  --bg-primary-300: #542f68;
  --bg-primary-200: #ddcaeb;
  --bg-primary-100: #f4eff9;
  --bg-secondary-300: #c19bd9;
  --bg-secondary-200: #dfd2e6;
}

.primary-hover:hover {
  background-color: var(--bg-secondary-300) !important;
  transition: all 0.1s;
  color: #fff;
}

.primary-900 {
  color: var(--primary-900);
}

.primary-700 {
  color: var(--primary-700);
}

.primary-500 {
  color: var(--primary-500);
}

.primary-300 {
  color: var(--primary-300);
}

.primary-100 {
  color: var(--primary-100);
}

.bg-primary-700 {
  background-color: var(--bg-primary-700);
}

.bg-primary-500 {
  background-color: var(--bg-primary-500);
}

.bg-primary-400 {
  background-color: var(--bg-primary-400);
}

.bg-primary-300 {
  background-color: var(--bg-primary-300);
}

.bg-primary-200 {
  background-color: var(--bg-primary-200);
}

.bg-primary-100 {
  background-color: var(--bg-primary-100);
}

.bg-secondary-300 {
  background-color: var(--bg-secondary-300);
}

.bg-secondary-200 {
  background-color: var(--bg-secondary-200);
}

.border-primary-500 {
  border: 4px solid var(--primary-500);
}

.border-secondary-500 {
  border: 1px solid var(--primary-500);
}

.border-primary-300 {
  border-color: var(--primary-300);
}

.slider3d .swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.slider3d .swiper-image {
  border-radius: 10px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slider3d .swiper-slide:not(.swiper-slide-active) {
  transform: scale(0.8) translateX(110%) !important;
  /* Scale down the non-active slides */
  opacity: 0.6;
  /* Reduce opacity for non-active slides */
  z-index: 1;
  /* Place behind the active slide */
}

.slider3d .swiper-slide-active {
  transform: scale(1) translateX(100%) !important;
  /* Full size for the active slide */
  z-index: 10;
  /* Place it on top */
}

.about-carousels .swiper-pagination {
  bottom: 4px !important;
}

@media (max-width: 1450px) {
  .slider3d .swiper-slide-active {
    transform: scale(1) translateX(108%) !important;
    z-index: 10;
  }
}

@media (max-width: 1200px) {
  .slider3d .swiper-slide-active {
    transform: scale(1) translateX(112%) !important;
    z-index: 10;
  }
}

@media (max-width: 390px) {
  .slider3d .swiper-slide-active {
    transform: scale(1) translateX(123%) !important;
    z-index: 10;
  }
}

/* Style for Swiper dots */
.about-carousels .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: var(--primary-300);
  /* Non-active dots are green */
  opacity: 1;
  /* Make sure bullets are fully opaque */
  transition: background-color 0.3s ease;
  opacity: 0.5;
}

.slider3d .swiper-pagination-bullet-active {
  background-color: var(--primary-300) !important;
  /* Active dot is red */
  opacity: 1 !important;
}

/* swiper */

.about-container .swiper_container {
  height: 375px;
  padding: 2rem 0;
  position: relative;
}

.about-container .swiper-slide {
  width: 37rem;
  height: auto;
  position: relative;
}

@media (max-width: 500px) {
  .about-container .swiper_container {
    height: 47rem;
  }

  .about-container .swiper-slide {
    width: 28rem !important;
    height: 36rem !important;
  }

  .about-container .swiper-slide img {
    width: 28rem !important;
    height: 36rem !important;
  }
}

.about-container .swiper-slide img {
  width: 37rem;
  height: 42rem;
  border-radius: 2rem;
  object-fit: cover;
}

.about-container .swiper-slide-shadow-left,
.about-container .swiper-slide-shadow-right {
  display: none;
}

.about-container .slider-controler {
  position: relative;
  bottom: 82px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-container .slider-controler .swiper-button-next {
  left: 58% !important;
  transform: translateX(-58%) !important;
}

@media (max-width: 990px) {
  .about-container .slider-controler .swiper-button-next {
    left: 70% !important;
    transform: translateX(-70%) !important;
  }
}

@media (max-width: 450px) {
  .about-container .slider-controler .swiper-button-next {
    left: 80% !important;
    transform: translateX(-80%) !important;
  }
}

@media (max-width: 990px) {
  .about-container .slider-controler .swiper-button-prev {
    left: 30% !important;
    transform: translateX(-30%) !important;
  }
}

@media (max-width: 450px) {
  .about-container .slider-controler .swiper-button-prev {
    left: 20% !important;
    transform: translateX(-20%) !important;
  }
}

.about-container .slider-controler .slider-arrow {
  background: var(--white);
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  left: 42%;
  transform: translateX(-42%);
  filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

.about-container .slider-controler .slider-arrow ion-icon {
  font-size: 2rem;
  color: #222224;
}

.about-container .slider-controler .slider-arrow::after {
  content: "";
}

.about-container .swiper-pagination {
  position: relative;
  width: 15rem !important;
  bottom: 1rem;
}

.about-container .swiper-pagination .swiper-pagination-bullet {
  filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
  background: #542f68;
  opacity: 0.5;
  height: 15px;
  width: 15px;
}

.about-container .swiper-pagination .swiper-pagination-bullet-active {
  background: #542f68;
  height: 15px;
  width: 15px;
  opacity: 1;
}

.about-container {
  height: 427px !important;
}

.about-container .swiper-3d .swiper-wrapper {
  height: 425px !important;
}

.about-container .swiper-slide-active {
  opacity: 1;
}

.about-container .swiper-slide:not(.swiper-slide-active) {
  opacity: 0.5;
  /* transform: translate3d(0px, 0px, -1000px) rotateX(0deg) rotateY(0deg) scale(1) translateX(50%) !important; */
}

.experience-carousel

/* Custom styles for Swiper dots */
.swiper-pagination-bullet {
  width: 22px;
  height: 22px;
  background-color: #fff !important;
  /* Default color for unselected dots */
  opacity: 0.5;
  /* Ensure visibility of all dots */
  transition: background-color 0.3s ease;
  /* Smooth color transition */
}

.experience-carousel .swiper-pagination-bullet-active {
  background-color: #fff;
  /* Color for the selected dot */
  opacity: 1 !important;
}

.exp-carousel .swiper-pagination {
  bottom: 35px !important;
}

@media (max-width: 768px) {
  .experience-carousel

  /* Custom styles for Swiper dots */
  .swiper-pagination-bullet {
    width: 12px !important;
    height: 12px !important;
  }

  .experience-carousel .swiper-pagination-fraction,
  .swiper-pagination-custom,
  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 15px !important;
  }
}

.testimonial-list .swiper-horizontal {
  padding: 5px !important;
}

/* @media(max-width: 768px){
  .testimonial-list .swiper-horizontal{
    padding: 0px 5px 60px !important;
  }
  
} */

/* pagination */

.pagination-div ul {
  display: flex !important;
  gap: 10px !important;
  justify-content: center;
  align-items: center;
  list-style-type: none;
}

.pagination li:not(:first-child):not(:last-child) a {
  display: block;
  /* Make the anchor tag fill the li */
  /* Apply padding here */
  background-color: rgba(84, 47, 104, 0.5);
  width: 40px;
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #fff;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
}

.pagination .active a {
  background-color: var(--primary-500) !important;
}

.custom-next-btn,
.custom-prev-btn {
  border-radius: 100px;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 0px 30px;
  cursor: pointer;
  width: 150px;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  height: 40px;
}

.custom-next-btn{
  margin-left: 14px;
}

.custom-prev-btn{
  margin-right: 14px;
}

@media (max-width: 1024px) {
  .custom-next-btn,
  .custom-prev-btn {
    width: fit-content;
    width: 40px;
    padding: 0;
    aspect-ratio: 1;
  }
}

@media (max-width: 768px) {
  .pagination li:not(:first-child):not(:last-child) a {
    width: 44px;
    padding: 0;
  }

  .custom-prev-btn {
    width: 44px;
    margin-right: 5px;
  }

  .custom-next-btn {
    width: 44px;
    margin-left: 5px;
  }

  .pagination-div ul {
    gap: 5px !important;
    padding-left: 0 !important;
  }
}

.my-masonry-grid {
  display: flex;
  row-gap: 20px;
  column-gap: 30px;
}

.my-masonry-grid_column {
  display: flex;
  flex-direction: column;
  gap: 20px;
}


.video-test-slider .swiper-pagination {
  display: none !important;
}

.popup-modal .ant-modal-content {
  border-radius: 50px !important;
}

.popup-modal .ant-modal-close {
  background-color: transparent !important;
}

@media (max-width: 1024px) {
  .popup-modal .ant-modal-content {
    border-radius: 30px !important;
    padding: 0px !important;
    min-height: 310px !important;
    height: fit-content !important;
  }
}

.popup-modals ul {
  padding-left: 0 !important;
}

.ant-form-item
  .ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  display: none !important;
}

.branch-select .ant-select-selector {
  border-radius: 0px 100px 100px 0px !important;
  background-color: var(--primary-100) !important;
}

.branch-select .ant-select-selection-placeholder {
  color: #2d1739;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex !important;
  justify-content: center;
}

.branch-select :hover .ant-select-selector {
  border: none !important;
  border-color: transparent !important;
}

.branch-select .ant-select-selector {
  border-color: transparent !important;
}

.branch-select .ant-select-selector {
  padding: 0 20px !important;
}

.branch-select .ant-select-selector {
  border: none !important;
}

.my-masonry-grid-career {
  width: 100%;
  display: flex;
  row-gap: 20px;
  column-gap: 30px;
}

@media (max-width: 1200px) {
  .branch-select .ant-select-selector {
    border-radius: 100px !important;
  }

  .my-masonry-grid-career {
    gap: 20px;
  }
}

@media (max-width: 768px) {
  .branch-select .ant-select-selection-placeholder {
    font-size: 12px;
  }

  .branch-select .ant-select-selector {
    height: 44px !important;
  }
}

.resume-modal .ant-modal-content {
  border-radius: 50px !important;
  padding: 50px 30px !important;

}

@media (max-width: 768px) {

  .resume-modal .ant-modal-content {
    border-radius: 30px !important;
    padding: 30px 30px !important;
  
  }
}


.resume-modal .ant-select-selector {
  height: 60px !important;
  background-color: var(--bg-primary-100) !important;
  border-radius: 10px !important;
}

.resume-modal .ant-select-selection-placeholder {
  color: #87828a;

  leading-trim: both;

  text-edge: cap;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px !important; /* 131.25% */
}

.resume-modal .ant-input {
  font-size: 16px !important;
}

.resume-modal .ant-select-arrow {
  top: 90% !important;
  font-size: 16px !important;
}

.resume-modal .ant-card-head {
  text-align: center;
  border-bottom: none !important;
  background-color: var(--bg-primary-100) !important;
}

.resume-modal .ant-card-body {
  display: flex;
  justify-content: center;
  padding-top: 0 !important;
  background-color: var(--bg-primary-100) !important;
  height: 125px !important;
}

.resume-modal .ant-card-head-title {
  color: #87828a;

  leading-trim: both;

  text-edge: cap;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 131.25% */
}

.resume-modal .ant-upload.ant-upload-select{
  border: none !important;
  background-color: transparent !important;
  display: flex;
  justify-content: center;
}

.resume-modal .ant-upload.ant-upload-select >.ant-upload{
  width: 55px !important;
  height: 55px !important
}

.resume-modal .ant-card{
  height: 164px !important;
}

/* Remove the background from the delete icon */
.resume-modal .ant-upload-list-item-action {
  background: transparent !important;
}

/* If the icon itself has a background */
.resume-modal .ant-upload-list-item-action .anticon-delete {
  background: none !important;
}

.resume-modal .ant-upload-list-item:hover .ant-upload-list-item-action{
  padding: 0 !important;
}

.resume-modal .ant-tooltip-inner, .resume-modal .ant-tooltip-placement-top>.ant-tooltip-arrow{
display: none;
}

.employee-status .ant-form-item-explain-error{
  margin-top: 19px !important;
}

.resume-modal .ant-modal-close-x{
  width: 30px;
  height: 30px;
  color: var(--primary-500);
}

.resume-modal .ant-select-outlined.ant-select-status-error:not(.ant-select-customize-input) .ant-select-selector{
  border: none !important;
}

@media(max-width: 768px){
  .resume-modal .ant-select-selector {
    height: 44px !important
  }

  .resume-modal .ant-select-selection-placeholder {
    font-size: 12px !important;
  }

  .resume-modal .ant-modal-content{
    padding: 30px !important
  }

  .resume-modal .ant-form-item{
    margin-bottom: 20px !important;
  }

  .resume-modal .ant-row{
    flex-direction: column;
  }

  .resume-modal .ant-col-12{
    max-width: 100%;
  }

  .resume-modal .ant-select-arrow {
    margin-top: -13px;
}

.resume-modal .ant-card-head-title{
  font-size: 12px !important;
}

.resume-modal .ant-upload.ant-upload-select >.ant-upload {
  width: 36px !important;
  height: 36px !important;
}

.resume-modal .ant-upload.ant-upload-select{
  width: 36px !important;
  height: 36px !important;
}

}

.disabled-button:hover{
  background-color: none !important;
}

@media(min-width: 769px){

.popup-modal .ant-modal-content{
  padding-top: 50px;
  padding-bottom: 50px;
}

.popup-modal .ant-modal-close {
  position: absolute;
  top: 31px;
  inset-inline-end: 30px;
  z-index: 1010;
  padding: 0;
  color: rgba(0,0,0,0.45);
  font-weight: 600;
  line-height: 1;
  text-decoration: none;
  background: transparent;
  border-radius: 4px;
  width: 32px;
  height: 32px;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.2s,background-color 0.2s;
}
}

.description-content figure {
  display: block;
  margin-inline-start: 0px !important;
  margin-inline-end: 0px !important;
  unicode-bidi: isolate !important;
  width: 100% !important;
}

.description-content figure img{
  width: 100% !important;
  height: auto !important;
}

.description-content td,.description-content th{
  border: 1px solid black;
  padding: 5px;
  
}

.description-content ol{
  padding-inline-start: 10px !important;

}

.description-content ul{
  padding-inline-start: 10px !important;
}



@media(max-width: 768px){
  .description-content td{
    text-align: center;
  }

  .description-content ol{
    padding-inline-start: 15px !important;
  
  }

  .description-content ul{
    padding-inline-start: 15px !important;
  }
}

.description-content table{
  border-collapse: collapse;
  
}



.description-content ul li{
  position: relative;
  /* padding-left: 20px; */
}

/* .description-content ul li::before {
  content: "■";
  background-size: 16px 16px; 
  background-repeat: no-repeat;
  background-size: contain;
  width: 5px;
  height: 5px;
  position: absolute;
  left: 0;
  top: -2px;
} */






