.footerContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: auto;
    position: static;
    bottom: 0;
}

.footerSection {
    position: relative;
    background-color: var(--bg-primary-700);
    /* changing padding in y direction will impact 'column' class width below */
    padding: 70px 50px 60px 50px;

    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: space-between;
    overflow: hidden;
}

.footerBack {
    width: 1011.47px;
    height: 758.611px;
    transform: rotate(41.619deg);
    /* z-index: 10; */

    position: absolute;
    right: -273.999px;
    bottom: -133.595px;
    /* right: -420.999px;
    bottom: -633.595px; */
}

.footerlogo {
    width: 290px;
}

.column {
    flex: 1;
    min-width: calc(33.33% -100px);
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.columnHeading {
    color: #FFF;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 31.5px;
}

.divideLine {
    background: #FFF;
    height: 1px;
    align-self: stretch;
}

.description {
    align-self: stretch;
    color: #FFF;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 133%;
}

.buttonDiv {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.bookOnline {
    cursor: pointer;
    display: flex;
    /* height: 44px; */
    align-items: center;
    justify-content: center;
    gap: 10px;
    background-color: #fff;
    border-radius: 100px;
    padding: 15px 20px;

    border: 4px solid var(--primary-500);

    color: var(--primary-700);
    font-size: 16px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
}

.exploreButton {
    display: flex;
    /* width: 300px; */
    padding: 15px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;

    border-radius: 100px;
    border: 1px solid #FFF;
    background: rgba(255, 255, 255, 0.05);

    color: #FFF;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
}

.quickContactDiv {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.contactTitle {
    color: #FFF;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
}

.quickContactItemDiv {
    display: flex;
    gap: 7px;
}

.quickContactItemDiv a {
    text-decoration: none;
    color: #FFF;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 21px;
    /* 150% */
    text-transform: capitalize;
}

.callIcon {
    width: 18px;
    /* height: 18px; */
}

.whatsappIcon {
    width: 18px;
    /* height: 18px; */
}

.quickContactSection {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.socialMediaDiv {
    display: flex;
    flex-direction: row;
    gap: 16px;
}

.socialMediaDiv a {
    text-decoration: none;
}

.socialMediaDiv img {
    width: 40px;
    height: 40px;
}

.listing {
    list-style-type: none;
    margin: 0;
    padding-left: 0;
}

.listing li {
    color: #FFF;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 19.5px;
    margin-bottom: 8px;
    position: relative;
    cursor: pointer;
    display: flex;
    gap: 10px;
}

.listing li p{
    margin-left: 10px;
}

.listing a {
    text-decoration: none;
    color: #FFF;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 19.5px;

    margin-left: 10px;
}

.listIcon {
    content: '';
    position: absolute;
    left: 0;
    top: 8px;
    /* top: 50%;
    transform: translateY(-50%); */
    width: 3px;
    height: 4px;
    background-color: white;
}

.quickLinkGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
}

.quickLinkGridCol {
    flex: 1 0 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.versionSection {
    padding: 20px 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    background: var(--bg-primary-300);
}

.versionText {
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
}

.versionText a {
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    text-decoration: none;
}

.iconCover{
    background-color: rgba(255, 255, 255, 0.08);
    border-radius: 50%;
    width: 40px;
    aspect-ratio: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.youtubeIcon{
    width: 25px !important;
}

@media (max-width:1200px) {
    .footerSection {
        padding: 70px 30px 60px 30px;
        /* justify-content: none; */
        flex-direction: column;
    }

    .column {
        min-width: 100%;
    }

    .exploreButton {
        /* flex: 1 1 0; */
        padding: 20px;
        gap: 10px;

        font-size: 16px;
    }
}

@media (max-width:768px) {
    .footerSection {
        padding: 60px 20px 50px 20px;
    }

    .footerlogo {
        width: 166px;
    }

    .description {
        font-size: 12px;
    }


    .bookOnline {
        font-size: 12px;
        padding: 5px 10px;
    }

    .exploreButton {
        padding: 5px 10px;
        gap: 10px;
        font-size: 12px;
    }

    .contactTitle {
        font-size: 14px;
    }

    .quickContactItemDiv a {
        font-size: 12px;
    }

    .callIcon {
        width: 15px;
        /* height: 18px; */
    }

    .whatsappIcon {
        width: 15px;
        /* height: 18px; */
    }

    .columnHeading {
        font-size: 14px;
    }

    .listing li {
        font-size: 12px;
    }

    .listing a {
        font-size: 12px;
    }

    .versionText {
        font-size: 12px;
    }

    .versionText a {
        font-size: 12px;
    }
}