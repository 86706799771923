.outer{
    width: 55%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    margin-bottom: 100px;
}

.outer h3{
    color: #212022;
font-family: Montserrat;
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: 133%;
}

.outer p{
    font-family: Montserrat;
}

.outer button{
    padding: 0 20px;
    background-color: #fff;
    height: 50px;
    border-radius: 30px;
    cursor: pointer;
    font-family: Montserrat;
    font-size: 16px;
}

@media(max-width: 900px){
    .outer img{
        width: 100%
    }
}

@media(max-width: 768px){
    .outer h3{
        font-size: 20px;
    }

    .outer{ gap: 10px;}

    .outer p{
        font-size: 12px;
    }

    .outer button{
        font-size: 12px;
        height: 44px;
        padding: 0 10px;
    }
}