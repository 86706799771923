.reportContainer {
    display: flex;
    flex-direction: column;
}

.heading {
    color: #212022;
    text-align: center;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 52px;
    margin-bottom: 8px;
}

.heading span {
    color: var(--primary-500);
}

.description {
    color: #000;
    text-align: center;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    margin-bottom: 32px;
}

.submitButton {
    cursor: pointer;
    display: flex;
    /* height: 44px; */
    align-items: center;
    justify-content: center;
    gap: 10px;
    background-color: #fff;
    border-radius: 100px;
    padding: 15px 20px;

    border: 4px solid var(--primary-500);

    color: var(--primary-700);
    font-size: 16px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;

    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
}

@media (max-width:768px) {
    .heading {
        font-size: 16px;
        line-height: normal;
        margin-bottom: 8px;
    }

    .description {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        margin-bottom: 30px;
    }

    .submitButton {
        font-size: 12px;
        padding: 5px 10px;
    }

}