.nav-menu .ant-drawer-body {
    padding: 34px 20px !important;
}

@media (min-width:768px) {
    .nav-menu .ant-drawer-body {
        padding: 40px 30px !important;
    }
}

@media (min-width:1200px) {
    .nav-menu .ant-drawer-body {
        padding: 80px 50px !important;
    }
}

/* sidemenu - dropdown */

.ant-menu {
    font-family: 'Montserrat', sans-serif !important;
}

.filter-menu {
    /* background-color: transparent !important; */
    /* background-color: var(--bg-primary-500) !important; */
    border-inline-end: none !important;
    width: 100% !important;
    font-family: 'Montserrat' !important;
}

.filter-menu .ant-menu-submenu {
    margin-bottom: 10px;
}

.filter-menu .ant-menu-submenu-open .ant-menu-submenu-title {
    color: #FFF !important;
    background-color: var(--primary-500) !important;
}

.filter-menu .ant-menu-submenu-open .ant-menu-title-content {
    color: #FFF !important;
}

.filter-menu .ant-menu-inline {
    background-color: transparent !important;
}

.filter-menu .ant-menu-submenu-title {
    margin: 0 !important;
    padding-left: 10px !important;
    width: 100% !important;
    border-radius: 5px !important;
}

.filter-menu .ant-menu-submenu-title {
    background-color: #FFF !important;
    color: var(--bg-primary-500) !important;
    height: 44px !important;
    font-family: 'Montserrat', sans-serif !important;
}

.filter-menu .ant-menu-item {
    height: fit-content;
    padding-left: 0px !important;
    padding-right: 0px !important;
    font-family: 'Montserrat', sans-serif !important;
}

.filter-menu .ant-menu-item:hover {
    background-color: transparent !important;
}

.filter-menu .ant-menu-item-selected {
    color: #FFFFFF !important;
    background-color: transparent !important;
}

/* .filter-menu .ant-menu-submenu, .ant-menu-submenu-title {
    height: 44px !important;
  } */

.filter-menu .ant-menu-title-content {
    color: var(--primary-500) !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
}

.filter-menu .ant-menu-sub {
    border-radius: 5px !important;
    background-color: var(--bg-primary-100) !important;
}

.filter-menu .ant-menu-sub .ant-menu-title-content {
    color: var(--primary-500) !important;
    padding-left: 20px !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
}

@media (max-width:768px) {
    .filter-menu .ant-menu-submenu {
        margin-bottom: 5px;
    }

    .filter-menu .ant-menu-title-content {
        color: var(--primary-500) !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
    }

    .filter-menu .ant-menu-sub .ant-menu-title-content {
        color: var(--primary-500) !important;
        padding-left: 15px !important;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
    }
}

/* swiper -banner */
.outer-outer {
    width: 100%;
    margin: 0 auto;
}


/* Custom styles for Swiper dots */
.swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: red;
    /* Default color for unselected dots */
    opacity: 1;
    /* Ensure visibility of all dots */
    transition: background-color 0.3s ease;
    /* Smooth color transition */
}

.swiper-pagination-bullet-active {
    background-color: green;
    /* Color for the selected dot */
}

.banner-swiper .swiper-pagination {
    bottom: 20px !important;
    background-color: var(--primary-500);
    padding: 4px 8px;
    border-radius: 100px;
    width: fit-content !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
    display: flex;
    align-items: center;
}

.branch-banner-swiper .swiper-pagination {
    display: none !important;
}

.banner-swiper .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    background-color: #fff;
    opacity: 0.5;
}

.banner-swiper .swiper-pagination-bullet-active {
    background-color: #fff;
    opacity: 1;
}

@media(max-width: 1600px) {
    .banner-swiper .swiper-pagination {
        bottom: 15px;
    }
}

@media (max-width: 1200px) {
    .banner-swiper .swiper-pagination {
        bottom: 20px !important;
    }
}

@media (max-width: 768px) {
    .banner-swiper .swiper-pagination {
        bottom: 20px !important;
        padding: 4px 8px;
        border-radius: 100px;
        width: fit-content !important;
        left: 50% !important;
        transform: translateX(-50%) !important;
        display: flex;
        align-items: center;
    }

    .banner-swiper .swiper-pagination-bullet {
        width: 8px;
        height: 8px;
    }
}

.search-select {
    width: 200px;
    height: 44px;
    border-radius: 100px !important;
}

.search-select .ant-select-selection-placeholder {
    color: var(--primary-700);
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.search-select .ant-select-selector {
    border-radius: 100px !important;
    padding: 0 20px !important;
}

.search-select .ant-select-prefix {
    margin-top: auto !important;
    margin-bottom: auto !important;
}

.search-select:has(.ant-select-clear:hover) .ant-select-arrow {
    opacity: 0;
}

.search-select .ant-select-clear {
    right: 18px !important;
    width: 20px !important;
    height: 20px !important;
    color: var(--primary-500) !important;
}

@media (max-width:1200px) {
    .search-select {
        width: 100% !important;
    }
}

@media (max-width:768px) {
    .search-select {
        width: 100% !important;
        height: 40px;
    }

    .search-select .ant-select-selection-placeholder {
        font-size: 12px;
    }
}

.form-select .ant-select-selection-placeholder {
    color: #87828A;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
}

.form-select {
    height: 60px !important;
}

.form-select .ant-select-selector {
    border-radius: 10px !important;
    padding: 15px !important;
    background: var(--bg-primary-100) !important;
    border: none !important;
}

@media (max-width:1200px) {}

@media (max-width:768px) {
    .form-select {
        width: 100% !important;
        height: 44px !important;
    }

    .form-select .ant-select-selection-placeholder {
        font-size: 12px;
    }
}

.video-modal .ant-modal-body {
    margin-top: 28px !important;
}

.dropdown-swiper {
    height: inherit;
}

.dropdown-swiper .swiper-pagination {
    bottom: 25px !important;
    left: 25px !important;
    padding: 10px 0px;
    width: fit-content !important;
    border-radius: 100px;
    display: flex;
    align-items: center;
}

.dropdown-swiper .swiper-pagination-bullet {
    width: 15px;
    height: 15px;
    background-color: #fff;
    opacity: 0.5;
}

.dropdown-swiper .swiper-pagination-bullet-active {
    background-color: #fff;
    opacity: 1;
}

.lab-report .ant-modal-content {
    border-radius: 50px !important;
    padding: 50px 100px;
}

@media (max-width:1200px) {
    .lab-report .ant-modal-content {
        border-radius: 50px !important;
        padding: 50px;
    }
}

@media (max-width:768px) {
    .lab-report .ant-modal-content {
        border-radius: 30px !important;
        padding: 30px;
    }
}

.ant-form-item-label {
    font-size: 14px !important;
    color: #212022;
    font-weight: 600 !important;
}

.ant-input {
    height: 60px !important;
    border-radius: 10px !important;
    background: var(--bg-primary-100) !important;
}

.ant-input::placeholder {
    color: #87828A !important;
    font-size: 14px !important;
}

.ant-input-outlined {
    border: none !important;
}

.ant-form-item-explain-error {
    font-size: 14px !important;
}

@media (max-width:768px) {
    .ant-form-item-label {
        font-size: 12px !important;
    }

    .ant-input {
        height: 44px !important;
    }

    .ant-input::placeholder {
        font-size: 12px !important;
    }

    .ant-form-item-explain-error {
        font-size: 12px !important;
    }
}

.text-area .ant-input {
    height: auto !important;
    resize: none !important;
}

.phone-input .react-tel-input .form-control {
    width: 100% !important;
    border-radius: 10px !important;
    height: 60px !important;
    border: none !important;
    background: var(--bg-primary-100) !important;
    font-size: 14px !important;
    padding: 4px 11px 4px 52px !important;
}

@media (max-width:768px) {
    .phone-input .react-tel-input .form-control {
        width: 100% !important;
        height: 44px !important;
        font-size: 12px !important;
    }
}


.dM {
    font-family: 'Montserrat', sans-serif !important;
}

.Er .jb {
    color: var(--primary-300);
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 88%;
    /* 21.12px */
}

._3n {
    font-family: 'Montserrat', sans-serif !important;
    border-radius: 20px !important;

    border: 1px solid var(--primary-300) !important;
    color: var(--primary-300) !important;

}

.day-name {
    color: var(--primary-300) !important;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.FC .day-name {
    color: #FFF !important;
}

.FC {
    background: var(--primary-300) !important;
    color: #fff !important;
    font-family: 'Montserrat', sans-serif !important;
}

.custom-date-label {
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    background: none !important;

}

.Ez:after {
    background: none !important;
}

@media (max-width: 1200px) {
    .Er .jb {
        font-size: 16px;
    }
}

@media (max-width: 768px) {
    .Er .jb {
        font-size: 14px;
    }

    ._3n {
        border-radius: 10px !important;
    }

    .day-name {
        font-size: 12px;
    }

    .custom-date-label {
        font-size: 14px;
    }
}

.otp-input {
    display: flex;
    width: 70px !important;
    height: 70px !important;
    padding: 10px !important;
    align-items: center !important;
    gap: 10px !important;
    border-radius: 10px !important;
    border: 1px solid #5D5D5D !important;

    font-size: 20px !important;
    margin-right: 16px !important;
}

@media (max-width: 1200px) {
    .otp-input {
        width: 70px !important;
        height: 70px !important;
        font-size: 18px !important;
    }
}

@media (max-width: 768px) {
    .otp-input {
        width: 40px !important;
        height: 40px !important;
        padding: 12px !important;
        border-radius: 5px !important;

        margin-right: 8px !important;
        font-size: 16px !important;
    }
}

/* share buttons last item make it always visible */
.st-last {
    display: inline-block !important;
}

.st-left {
    z-index: 98 !important;
}

.banner-tooltip .ant-tooltip-inner {
    font-size: 12px !important;
    background-color: var(--bg-primary-100) !important;
}