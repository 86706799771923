.dropdownContainer {
    width: 100%;
    max-height: 90vh;
    height: fit-content;
    position: absolute;
    top: 78px;
    z-index: 150;
    background: #FFF;


    transform-origin: top;
    overflow-y: auto;

    box-shadow: 0px 308px 86px 0px rgba(0, 0, 0, 0.00), 0px 197px 79px 0px rgba(0, 0, 0, 0.01), 0px 111px 66px 0px rgba(0, 0, 0, 0.03), 0px 49px 49px 0px rgba(0, 0, 0, 0.04), 0px 12px 27px 0px rgba(0, 0, 0, 0.05);
}

/* Opening animation */
.open {
    animation: slideDown 0.1s ease forwards;
}

/* Closing animation */
.close {
    animation: slideUp 0.1s ease forwards;
}

/* Keyframes for opening and closing */
@keyframes slideDown {
    from {
        opacity: 0;
        transform: scaleY(0);
    }

    to {
        opacity: 1;
        transform: scaleY(1);
    }
}

@keyframes slideUp {
    from {
        opacity: 1;
        transform: scaleY(1);
    }

    to {
        opacity: 0;
        transform: scaleY(0);
    }
}