.container2 {
    width: 91%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    /* gap: 50px; */

    position: relative;
}

.banner {
    width: 100%;
    height: 600px;
    border-radius: 50px;
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    /* margin-bottom: 100px; */
    position: relative;

    margin-bottom: 40px;
}

.banner h2 {
    width: 600px;
    text-align: center;
    font-family: Montserrat;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.icon {
    width: 208px;
    height: 208px;
}

@media(max-width: 1024px) {
    .banner {
        height: 400px;
        gap: 10px;
        /* margin-bottom: 50px; */
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .banner h2 {
        font-size: 32px;
        width: 481px;
    }


    .pattern {
        display: none;
    }
}

@media(max-width: 768px) {
    .banner {
        height: 250px;
    }

    .banner h2 {
        font-size: 20px;
        width: 63vw;
    }

    .icon {
        width: 150px;
        height: 150px;
    }
}