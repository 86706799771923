.sectionContianer {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.sectionHeading {
    color: #212022;
    font-family: Montserrat;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 46.8px;
    margin-bottom: 20px;
}

.sectionHeading span {
    color: var(--primary-500);
}

.patientSection {
    margin: 50px 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.dataRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.rowLeftSide {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.rowParameter {
    color: #212022;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
}

.rowValue {
    color: #212022;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
}

.rowIcon {
    width: 24px;
    height: 24px;
}

.divideLine {
    background: #A7A7A7;
    height: 1px !important;
    align-self: stretch;
}

.paymentMessage {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;

    color: #F00;
    text-align: center;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    margin-top: 16px;
    margin-right: auto;
    margin-left: auto;
}

.buttonDiv {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.submitButton {
    flex: 1 1 0;
    cursor: pointer;
    display: flex;
    /* height: 44px; */
    align-items: center;
    justify-content: center;
    gap: 10px;
    background-color: #fff;
    border-radius: 100px;
    padding: 15px 20px;

    border: 4px solid var(--primary-500);

    color: var(--primary-700);
    font-size: 16px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;

    /* margin-left: auto;
    margin-right: auto; */
    margin-top: 16px;
}

.submitButton:disabled {
    background: #D9D9D9;
    color: #fff;
}

.submitButton2 {
    flex: 1 1 0;
    cursor: pointer;
    display: flex;
    /* height: 44px; */
    align-items: center;
    justify-content: center;
    gap: 10px;
    background-color: #fff;
    border-radius: 100px;
    padding: 15px 20px;

    border: 1px solid var(--primary-700);

    color: var(--primary-700);
    font-size: 16px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;

    /* margin-left: auto;
    margin-right: auto; */
    margin-top: 16px;
}

@media(max-width: 1200px) {
    .sectionHeading {
        color: #212022;
        font-size: 32px;
        line-height: normal;
    }

    .patientSection {
        margin: 40px 0;
    }
}

@media(max-width: 768px) {
    .sectionHeading {
        color: #212022;
        font-size: 20px;
        line-height: normal;
    }

    .patientSection {
        margin: 30px 0;
    }

    .dataRow {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        justify-content: start;
    }

    .rowLeftSide {
        gap: 5px;
    }

    .rowParameter {
        font-size: 12px;
    }

    .rowValue {
        font-size: 14px;
    }

    .rowIcon {
        width: 12px;
    }

    .rowIcon {
        width: 16px;
        height: 16px;
    }

    .paymentMessage {
        gap: 5px;
        font-size: 12px;
    }

    .buttonDiv {
        gap: 10px;
    }

    .submitButton {
        font-size: 12px;
        padding: 5px 10px;
    }

    .submitButton2 {
        font-size: 12px;
        padding: 5px 10px;
    }
}