.outerContainer {
    padding: 30px 50px 50px 50px;
}

.loadingDiv {
    display: flex;
    justify-content: center;
}

.loadingIcon {
    font-size: 36px;
    color: var(--primary-300);
}

/* :global(.loadingIcon svg path) {
    stroke-width: 10;
} */

.menuContainer {
    width: 100%;
    display: flex;
    gap: 30px;
}

.leftSection {
    width: 50%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}

.leftSection a {
    text-decoration: none;
}

.card {
    width: 100%;
    display: flex;
    height: 70px;
    padding: 5px;
    align-items: center;
    gap: 20px;
    flex-shrink: 0;

    border-radius: 100px;
    cursor: pointer;
}

.card:hover {
    background: var(--bg-primary-200);
}

.logoDiv {
    display: flex;
    width: 60px;
    height: 60px;
    padding: 5px;
    justify-content: center;
    align-items: center;
    gap: 10px;

    border-radius: 100px;
    background: var(--bg-primary-100);
}

.logo {
    max-width: 40px;
    max-height: 40px;
}

.text {
    flex: 1 0 0;
    color: var(--primary-700);
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.rightSection {
    width: 50%;
    flex: 1 0 0;
}

.sliderContainer {
    width: 100%;
    height: 434px;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
}


.sliderImage {
    width: 100%;
    object-fit: cover;
    height: 100%;
    border-radius: 20px;
    overflow: hidden;
}

.swiperImageText {
    position: absolute;
    left: 25px;
    bottom: 76px;

    color: #FFF;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.buttonsDiv {
    display: flex;
    gap: 20px;
    position: absolute;
    bottom: 25px;
    right: 25px;
    z-index: 10;
}

.leftIcon {
    cursor: pointer;
    /* color: #FFF; */
}

.rightIcon {
    cursor: pointer;
    /* color: #FFF; */
}